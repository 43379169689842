<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom card-sticky">
              <div class="card-header" style="">
                <div class="card-title">
                  <h3 class="card-label">{{ $t("GENERAL.ADD_HOURLYRATE") }}</h3>
                </div>
                <CardToolbar
                  @submitEvent="onSubmit"
                  back-url="/hourly-rates/list"
                  buttonText="Add Hourly Rate"
                ></CardToolbar>
              </div>
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <div class="row justify-content-center">
                      <div class="col-xl-9">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.DATE") }}:
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <vc-date-picker
                                v-model="hourlyRate.date"
                                :masks="masks"
                              >
                                <template
                                  v-slot="{ inputValue, togglePopover }"
                                >
                                  <div
                                    class="input-group"
                                    @click="togglePopover()"
                                  >
                                    <input
                                      id="date"
                                      :class="{ 'is-invalid': errors[0] }"
                                      class="form-control"
                                      :value="inputValue"
                                      name="date"
                                      type="text"
                                    />
                                    <div
                                      class="input-group-prepend cursor-pointer"
                                    >
                                      <span class="input-group-text"
                                        ><i class="la la-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </template>
                              </vc-date-picker>
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("MENU.WORKERS") }}:
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <v-select
                                class="form-control form-control--construction-select"
                                :class="{ 'is-invalid': errors[0] }"
                                :placeholder="$t('MENU.WORKERS')"
                                v-model="hourlyRate.worker"
                                :options="workers"
                                :reduce="(worker) => worker['@id']"
                                label="firstName"
                              >
                                <template #option="{ firstName, lastName }">
                                  <span>{{ firstName }} {{ lastName }}</span>
                                </template>
                              </v-select>
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.CONSTRUCTION_SITE") }}:
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <v-select
                                class="form-control form-control--construction-select"
                                :class="{ 'is-invalid': errors[0] }"
                                :placeholder="$t('GENERAL.CONSTRUCTION_SITE')"
                                v-model="hourlyRate.construction"
                                :options="constructions"
                                :reduce="(construction) => construction['@id']"
                                label="name"
                              >
                              </v-select>
                            </div>
                          </div>
                        </validation-provider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.WORK_HOURS") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <select
                              class="form-control"
                              v-model="hourlyRate.workHours"
                            >
                              <option value="00:00">00:00</option>
                              <option value="00:30">00:30</option>
                              <option value="01:00">01:00</option>
                              <option value="01:30">01:30</option>
                              <option value="02:00">02:00</option>
                              <option value="02:30">02:30</option>
                              <option value="03:00">03:00</option>
                              <option value="03:30">03:30</option>
                              <option value="04:00">04:00</option>
                              <option value="04:30">04:30</option>
                              <option value="05:00">05:00</option>
                              <option value="05:30">05:30</option>
                              <option value="06:00">06:00</option>
                              <option value="06:30">06:30</option>
                              <option value="07:00">07:00</option>
                              <option value="07:30">07:30</option>
                              <option value="08:00">08:00</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import ApiService from "@/core/services/api.service";
import vSelect from "vue-select";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "",
});

export default {
  name: "NewHourlyRates",
  components: { CardToolbar, vSelect, ValidationProvider, ValidationObserver },
  data() {
    return {
      hourlyRate: {
        workHours: null,
        overtimeHr: null,
        weekendHr: null,
        dailyWage: null,
        nightHr: null,
        date: new Date(),
        worker: null,
        construction: null,
      },
      workers: [],
      isWorkersLoaded: false,
      constructions: [],
      isConstructionsLoaded: false,
      masks: {
        input: "DD.MM:YYYY.",
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_HOURLY_RATES"), route: "/hourly-rates/list" },
      { title: this.$t("MENU.NEW_HOURLY_RATE") },
    ]);

    ApiService.get("workers").then(({ data }) => {
      this.workers = data["hydra:member"];
      this.isWorkersLoaded = true;
    });

    ApiService.get("constructions").then(({ data }) => {
      this.constructions = data["hydra:member"];
      this.isConstructionsLoaded = true;
    });

    // document.getElementById("date").click();
  },
  methods: {
    onSubmit(type) {
      this.$refs.observerRef.validate().then((isValid) => {
        if (isValid) {
          ApiService.post("hourly_rates", this.hourlyRate)
            .then(() => {
              this.$notify({
                group: "notification",
                type: "success",
                title: this.$t("MENU.HOURLY_RATE_ADDED"),
              });
              if (type === "1") {
                setTimeout(() => {
                  this.$router.push({ name: "hourly-rates-list" });
                }, 1000);
              }
            })
            .catch((error) => {
              this.$notify({
                group: "notification",
                type: "error",
                title: error,
              });
            });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("USER.MANDATORY"),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
